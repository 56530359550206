import React from "react";
import {useTranslation} from 'react-i18next';
import {BrowserRouter} from "react-router-dom";
import classes from "./Footer.module.scss";
import Popup from "reactjs-popup";
import classNames from "classnames/bind";
import {isMobile} from "react-device-detect";
import FlagPl from '../../assets/images/pl.jpg';
import FlagDe from '../../assets/images/de.jpg';
import FlagUa from '../../assets/images/uk.jpg';
import FlagRo from '../../assets/images/ro.jpg';
import TelephoneIcon from '../../assets/images/phone.png';
import WhatsAppIcon from '../../assets/images/whatsapp.png';
import VyberIcon from '../../assets/images/vyber.png';
import Config from '../../config.json';

const Footer = () => {
    const {t} = useTranslation();
    const styling = {background: 'transparent', width: '100%', padding: '0 1rem', border: 'none', margin: '0'};
    const overlayStyling = {background: 'rgba(0,0,0,0.9)'};

    let cx = classNames.bind(classes);

    const contentClassNames = cx({
        footer: true,
        isNotMobile: !isMobile
    });

    return (
        <BrowserRouter>
            <div className={contentClassNames}>
                <div>
                    <a href="/impressum">{t('default:impressum')}</a>
                    <a href="/deleteAccount">{t('default:deleteAccount')}</a>
                    <div>
                        <Popup
                            modal
                            open={false}
                            position='top center'
                            contentStyle={styling}
                            overlayStyle={overlayStyling}
                            lockScroll={true}
                            closeOnDocumentClick={true}
                            trigger={<p>{t('default:contact')}</p>}
                        >
                            {close => (
                                <div className={classes.Contact}>
                                    <h3 className={classes.subHeadline}>{t('default:contact')}</h3>

                                    <div className={classes.telephone}>
                                        <img src={TelephoneIcon} className={classes.icon} alt="icon"/>

                                        <a href={"tel:" + Config.COUNTRIES.DE.TEL_LINK}>{Config.COUNTRIES.DE.TEL}</a>

                                        <div>
                                            <img src={FlagDe} className={classes.symbol} alt="flag"/>
                                            <img src={FlagUa} className={classes.symbol} alt="flag"/>
                                            <img src={FlagPl} className={classes.symbol} alt="flag"/>
                                            <img src={FlagRo} className={classes.symbol} alt="flag"/>
                                        </div>
                                    </div>

                                    <div className={classes.messenger}>
                                        <div className={classes.icons}>
                                            <img src={WhatsAppIcon} className={classes.icon} alt="icon"/>
                                            <img src={VyberIcon} className={classes.icon} alt="icon"/>
                                        </div>

                                        <div>
                                            <img src={FlagUa} className={classes.symbol} alt="flag"/>
                                            <a href={"tel:" + Config.COUNTRIES.UA.TEL_LINK}>{Config.COUNTRIES.UA.TEL}</a>
                                        </div>

                                        <div>
                                            <img src={FlagPl} className={classes.symbol} alt="flag"/>
                                            <a href={"tel:" + Config.COUNTRIES.PL.TEL_LINK}>{Config.COUNTRIES.PL.TEL}</a>
                                        </div>
                                        <div>
                                            <img src={FlagRo} className={classes.symbol} alt="flag"/>

                                            <a href={"tel:" + Config.COUNTRIES.RO.TEL_LINK}>{Config.COUNTRIES.RO.TEL}</a>
                                        </div>
                                    </div>

                                    <button onClick={close}>{t('default:close')}</button>
                                </div>
                            )}
                        </Popup>
                    </div>
                    <a href="/privacy">{t('default:privacy')}</a>
                </div>
            </div>
        </BrowserRouter>
    );
}

export default Footer;