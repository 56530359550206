import React, {Component} from "react";
import {withTranslation} from 'react-i18next';
import classes from './Home.module.scss';
import NoCostsButton from '../../assets/images/noCostsButton.png';
import NoCostsButtonUa from '../../assets/images/noCostsButton-ua.png';
import NoCostsButtonPl from '../../assets/images/noCostsButton-pl.png';
import NoCostsButtonRo from '../../assets/images/noCostsButton-ro.png';
import PickerAppPreview from '../../assets/images/PickerAppPreview.jpg';
import PdfDownloadIcon from '../../assets/images/pdfDownloadIcon.svg';
import MoreExperience from '../../assets/images/moreExperience.svg';
import ExperienceGrey from '../../assets/images/experienceGrey.svg';
import ExperienceGreen from '../../assets/images/experienceGreen.svg';
import AvatarFirst from '../../assets/images/avatarFirst.jpg';
import AvatarSecond from '../../assets/images/avatarSecond.jpg';
import AvatarThird from '../../assets/images/avatarThird.jpg';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import FooterLink from "../../components/FooterLink/FooterLink";
import TrainingVideos from "../../components/TutorialVideos/TrainingVideos";
import {isMobile} from 'react-device-detect';
import classNames from "classnames/bind";
import SocialMedia from "../SocialMedia/SocialMedia";
import ContactForm from "../ContactForm/ContactForm";

class Home extends Component {
    render() {
        const allowedLanguages = process.env.REACT_APP_LANGUAGES.split(',');
        let actualLanguage = this.props.i18n.resolvedLanguage;
        if (allowedLanguages.includes(actualLanguage) === false) {
            actualLanguage = actualLanguage.substring(0,2);
            this.props.i18n.changeLanguage(actualLanguage);
        }

        let cx = classNames.bind(classes);

        const contentClassNames = cx({
            home: true,
            isNotMobile: !isMobile
        });

        const language = actualLanguage === 'ua' ? 'uk' : actualLanguage;

        const fibel = process.env.REACT_APP_MEDIA_ROOT + 'pdfs/fibel-' + language + '.pdf';
        const recruitingVideo = process.env.REACT_APP_MEDIA_ROOT + 'videos/recruiting-' + language + '.mp4';

        let noCost = NoCostsButton;
        if (this.props.i18n.language === 'ua') {
            noCost = NoCostsButtonUa;
        }
        if (this.props.i18n.language === 'pl') {
            noCost = NoCostsButtonPl;
        }
        if (this.props.i18n.language === 'ro') {
            noCost = NoCostsButtonRo;
        }
        return (
            <div className={contentClassNames}>
                {/* Video */}
                <div className={classes.videoWrapper}>
                    <video src={recruitingVideo} playsInline muted autoPlay={true} controls={true}/>
                </div>
                {/* more Infos */}
                <div className={classes.moreInfos}>
                    <div className={classes.moreInfosWrapper}>
                        <h3>{this.props.t('translations:moreInfos.headline')}</h3>
                        <p>{this.props.t('translations:moreInfos.text')}</p>
                        <p className={classes.info}>{this.props.t('translations:moreInfos.info')}</p>
                    </div>
                </div>

                <img className={classes.noCostsButton} src={noCost} alt="no costs"/>

                {/* Tutorial Videos */}
                <TrainingVideos language={language}/>

                {/* Picker Fibel */}
                <div className={classes.fibel}>
                    <h3>{this.props.t('translations:fibel.headline')}</h3>
                    <p>{this.props.t('translations:fibel.text')}</p>
                    <div>
                        <a href={fibel} target="_blank">
                            <img src={PdfDownloadIcon} alt="asdasd"/>
                            <p>{this.props.t('translations:fibel.buttonText')}</p>
                        </a>
                    </div>
                </div>

                {/* App Advertisment */}
                <div className={classes.pickerAppPreview}>
                    <img src={PickerAppPreview} alt="picker app preview"/>
                    <div><p>{this.props.t('translations:advertisementText')}</p></div>
                </div>


                {/* Experiences */}
                <div className={classes.experiences}>
                    <h2>{this.props.t('translations:experiences.headline')}</h2>
                    <p>{this.props.t('translations:experiences.text')}</p>

                    <div className={classes.singleExperienceContainer}>
                        <div className={classes.singleExperience}>
                            <div className={classes.avatar}>
                                <img className={classes.avatarBackground} src={ExperienceGrey}
                                     alt="experience border"/>
                                <img src={AvatarFirst} className={classes.avatarImage}
                                     alt="avatar"/>
                            </div>

                            <p>{this.props.t('translations:experiences.first')}</p>
                        </div>
                        <div className={classes.singleExperience}>
                            <p>{this.props.t('translations:experiences.second')}</p>

                            <div className={classes.avatar}>
                                <img className={classes.avatarBackground} src={ExperienceGreen}
                                     alt="experience border"/>
                                <img src={AvatarSecond} className={classes.avatarImage}
                                     alt="avatar"/>
                            </div>
                        </div>
                        <div className={classes.singleExperience}>
                            <div className={classes.avatar}>
                                <img className={classes.avatarBackground} src={ExperienceGrey}
                                     alt="experience border"/>
                                <img src={AvatarThird} className={classes.avatarImage}
                                     alt="avatar"/>
                            </div>

                            <p>{this.props.t('translations:experiences.third')}</p>
                        </div>
                    </div>

                    <div className={classes.moreExperiences}>
                        <img src={MoreExperience} alt="more experiences"/>
                        <p>{this.props.t('translations:experiences.buttonText')}</p>
                    </div>
                </div>

                <SocialMedia className={classes.SocialMedia} />

                <ContactForm />

                <FooterLink/>
            </div>
        );
    }
}

export default withTranslation()(Home);