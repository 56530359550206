import React, {Component, Suspense} from 'react';
import axios from 'axios';
import classes from './App.module.scss'
import '../utilities/i18n'
import {BrowserRouter} from "react-router-dom";
import {Route, Routes} from "react-router";
import Home from "./Home/Home";
import Header from "./Header/Header";
import Footer from "./Footer/Footer";
import Impressum from "./Impressum/Impressum";
import Privacy from "./Privacy/Privacy";
import ReactGA from 'react-ga';
import {isMobile} from 'react-device-detect';
import classNames from "classnames/bind";
import AppStore from "./AppStore/AppStore";
import DeleteAccount from "./DeleteAccount/DeleteAccount";

class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            languages: null
        }

        ReactGA.initialize('UA-13153497-15');
        ReactGA.pageview(window.location.pathname);
    }

    componentDidMount() {
        if (!this.state.languages) {
            axios.get(process.env.REACT_APP_API_URL + 'languages').then(
                response => {
                    this.setState({languages: response.data});
                }
            )
        }
    }

    render() {
        let cx = classNames.bind(classes),
            content = <BrowserRouter>
                <Routes>
                    <Route exact path="/" element={<Home />}/>
                    <Route path="/impressum" element={<Impressum />}/>
                    <Route path="/deleteAccount" element={<DeleteAccount />}/>
                    <Route path="/privacy" element={<Privacy />}/>
                    <Route path="/appstore" element={<AppStore />}/>
                </Routes>
            </BrowserRouter>;

        const contentClassNames = cx({
            content: true,
            isNotMobile: !isMobile
        });

        return (
            <Suspense fallback={null}>
                <Header hasReturn={true} languages={this.state.languages}/>

                <div className={contentClassNames}>
                    {content}
                </div>

                <Footer/>
            </Suspense>
        )
    }
}

export default App;
